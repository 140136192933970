import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ to, label }) => (
  <Link
    to={to}
    className="block text-slate-300 hover:text-sky-500 transition duration-300"
  >
    {label}
  </Link>
);

const GlobalFooter = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-800 via-purple-800 to-blue-900 text-slate-100 py-8 mt-8">
      <div className="container mx-auto px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <h4 className="text-lg font-semibold mb-3">Company Info</h4>
            <FooterLink to="/about-us" label="About Us" />
            <FooterLink to="/contact" label="Contact" />
            <FooterLink to="/privacy-policy" label="Privacy Policy" />
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-3">Pricing</h4>
            <FooterLink to="/pricing" label="Pricing" />
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-3">Newsletter</h4>
            <p>Stay updated with our latest trends</p>
            <form className="flex mt-3">
              <input
                type="email"
                placeholder="Enter email"
                className="p-2 rounded-l bg-slate-700 text-slate-100 placeholder-slate-400 focus:outline-none"
              />
              <button className="px-3 py-2 bg-sky-600 hover:bg-sky-500 text-slate-100 rounded-r">
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className="text-center mt-6 border-t border-slate-700 pt-4">
          <p>Designed and styled by navanil</p>
          <p>
            &copy; {new Date().getFullYear()} navanil. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
