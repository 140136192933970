import React from "react";
import diversity from "assests/diversity.webp";
import journey from "assests/journey.webp";
import mission from "assests/mission.webp";
import logo1 from "assests/mongodb-logo.svg";
import logo2 from "assests/yelp_logo.webp";
import logo3 from "assests/shaw-floors-vector.svg";
import logo4 from "assests/nilfisk-logo.webp";
import logo5 from "assests/dd_logo.svg";
import logo6 from "assests/snowflake-logo.svg";
import logo7 from "assests/thomson-reuters-logo-vector.svg";
import logo8 from "assests/box-logo-blue.svg";
import { useNavigate } from "react-router-dom";

/**
 * AboutUs component
 * @returns {jsx}
 */
const AboutUs = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
  };

  return (
    <div className="container mx-auto px-4 py-12">
      {/* Основной заголовок */}
      <h1 className="text-5xl font-extrabold text-center text-brand mb-16">
        About Navan
      </h1>

      {/* Секция миссии */}
      <div className="mb-16 flex flex-col lg:flex-row items-center">
        <div className="flex-1 text-center lg:text-left lg:pr-8 mb-8 lg:mb-0">
          <h2 className="text-4xl font-bold text-brand mb-4">Our Mission</h2>
          <p className="text-lg text-gray-700 mb-6">
            Navan is the next generation of software designed to make business
            travel and expense management seamless. Our mission is to empower
            users to easily book, manage, and optimize business travel and
            expenses while fostering human connections and helping companies
            make informed decisions.
          </p>
          <ul className="list-disc list-inside text-lg text-gray-700 space-y-4">
            <li>Enabling human connections through travel</li>
            <li>Helping companies make data-driven decisions</li>
            <li>Meeting modern business needs with cutting-edge technology</li>
          </ul>
        </div>
        <div className="flex-1">
          <img
            src={mission}
            alt="Our mission image"
            className="rounded-lg shadow-lg"
          />
        </div>
      </div>

      {/* Секция истории компании */}
      <div className="mb-16 flex flex-col lg:flex-row-reverse items-center">
        <div className="flex-1 text-center lg:text-left lg:pl-8 mb-8 lg:mb-0">
          <h2 className="text-4xl font-bold text-brand mb-4">Our Journey</h2>
          <p className="text-lg text-gray-700 mb-6">
            Founded in 2015, Navan has grown from a user-centered travel
            platform to a multi-faceted solution for modern business needs. By
            leveraging AI and data science, we are transforming the legacy
            travel and expense industry.
          </p>
          <ul className="list-disc list-inside text-lg text-gray-700 space-y-4">
            <li>2015: Navan founded by Ariel Cohen and Ilan Twig</li>
            <li>2021: Acquired Reed & Mackay to expand globally</li>
            <li>2023: Expanded into India with Tripeur acquisition</li>
          </ul>
        </div>
        <div className="flex-1">
          <img
            src={journey}
            alt="Our journey image"
            className="rounded-lg shadow-lg"
          />
        </div>
      </div>

      {/* Секция разнообразия, равенства и инклюзивности */}
      <div className="flex flex-col lg:flex-row items-center">
        <div className="flex-1 text-center lg:text-left lg:pr-8 mb-8 lg:mb-0">
          <h2 className="text-4xl font-bold text-brand mb-4">
            Diversity, Equity & Inclusion
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            At Navan, we value diversity, equity, and inclusion as core
            strengths that drive innovation. Our team is dedicated to creating
            an environment where everyone can bring their authentic selves to
            work and thrive.
          </p>
          <button
            onClick={handleClick}
            className="bg-brand text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-brand-dark transition duration-300"
          >
            Learn More
          </button>
        </div>
        <div className="flex-1">
          <img
            src={diversity}
            alt="Diversity and inclusion image"
            className="rounded-lg shadow-lg"
          />
        </div>
      </div>

      {/* Раздел с логотипами партнеров */}
      <div className="my-16 text-center">
        <h3 className="text-3xl font-bold text-brand mb-8">
          Trusted by Global Brands
        </h3>
        <div className="flex flex-wrap justify-center items-center gap-6">
          {/* Пример логотипов компаний */}
          <img src={logo1} alt="Company logo 1" className="h-12" />
          <img src={logo2} alt="Company logo 2" className="h-12" />
          <img src={logo3} alt="Company logo 3" className="h-12" />
          <img src={logo4} alt="Company logo 4" className="h-12" />
          <img src={logo5} alt="Company logo 5" className="h-12" />
          <img src={logo6} alt="Company logo 6" className="h-12" />
          <img src={logo7} alt="Company logo 7" className="h-12" />
          <img src={logo8} alt="Company logo 8" className="h-12" />
          {/* Добавить больше логотипов по необходимости */}
        </div>
      </div>

      {/* Раздел CTA */}
      <div className="text-center mt-12">
        <button
          onClick={handleClick}
          className="bg-brand text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-brand-dark transition duration-300"
        >
          Join Us and See Where Navan Will Take You
        </button>
      </div>
    </div>
  );
};

export default AboutUs;
