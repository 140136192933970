import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "components/hamburger-menu/HamburgerMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "contexts/AuthContext";
import NavbarItems from "components/navbar-items/NavbarItems";

const GlobalNavbar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);

  const onHamburgerMenuToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="relative flex flex-wrap justify-between items-center px-6 py-3 md:px-12 bg-gradient-to-r from-blue-800 via-purple-800 to-blue-900 text-slate-100 shadow-lg">
      <div className="flex items-center">
        <Link to="/">
          <span className="font-bold text-lg">navanil</span>
        </Link>
      </div>
      <ul className="hidden md:flex">
        <NavbarItems isAuthenticated={isAuthenticated} />
      </ul>
      <FontAwesomeIcon
        icon={faBars}
        size="lg"
        color="#fff"
        className="block md:hidden cursor-pointer"
        onClick={onHamburgerMenuToggle}
      />
      <HamburgerMenu
        isVisible={isVisible}
        onHamburgerMenuToggle={onHamburgerMenuToggle}
        isAuthenticated={isAuthenticated}
      />
    </div>
  );
};

export default GlobalNavbar;
