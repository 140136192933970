import { Link, useNavigate, useLocation } from "react-router-dom";
import DropdownButton from "components/ux/dropdown-button/DropdownButton";

const NavbarItems = ({ isAuthenticated, onHamburgerMenuToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    navigate("/login");
  };

  const dropdownOptions = [
    { name: "Profile", onClick: () => navigate("/user-profile") },
    { name: "Logout", onClick: handleLogout },
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <>
      {["/", "/contact", "/pricing", "/about-us"].map((path, index) => (
        <li key={index} className="p-3 hover:bg-purple-700 rounded">
          <Link
            to={path}
            className={`text-slate-100 uppercase font-semibold ${
              isActive(path) && "underline"
            }`}
            onClick={onHamburgerMenuToggle}
          >
            {path === "/" ? "Home" : path.substring(1).replace("-", " ")}
          </Link>
        </li>
      ))}
      <li className="p-3">
        {isAuthenticated ? (
          <DropdownButton triggerType="click" options={dropdownOptions} />
        ) : (
          <Link
            to="/login"
            className={`text-slate-100 uppercase font-semibold ${
              isActive("/login") && "underline"
            }`}
            onClick={onHamburgerMenuToggle}
          >
            Login/Register
          </Link>
        )}
      </li>
    </>
  );
};

export default NavbarItems;
