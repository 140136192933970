import React from "react";

/**
 * Contact component
 * @returns {jsx}
 */
const Contact = () => {
  return (
    <div className="container mx-auto px-6 py-12">
      {/* Заголовок страницы */}
      <h1 className="text-5xl font-extrabold text-center text-gradient bg-gradient-to-r from-blue-600 via-purple-600 to-blue-700 text-transparent bg-clip-text mb-10">
        Contact Us
      </h1>

      {/* Приветствие и краткое описание */}
      <p className="text-xl text-center text-slate-200 mb-8 text-black">
        Whether you’re interested in becoming a partner, need assistance, or
        just want to say hello, we’re here to help! Connect with us via the form
        below or through our contact details.
      </p>

      {/* Секция с контактной информацией */}
      <div className="flex flex-col lg:flex-row lg:space-x-10 mb-12">
        {/* Офисное местоположение */}
        <div className="flex-1 bg-gradient-to-r from-slate-800 to-slate-900 p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold text-sky-400 mb-4">
            Our Office
          </h2>
          <p className="text-lg text-slate-300 mb-4">
            <strong>navanil Headquarters</strong>
            <br />
            865 Market St, San Francisco, CA 94103-1900
          </p>
          <p className="text-lg text-slate-300">
            <strong>Phone:</strong> +1 415-512-6776
            <br />
            <strong>Email:</strong>{" "}
            <a
              href="mailto:info@navanil.com"
              className="text-sky-400 hover:underline"
            >
              info@navanil.com
            </a>
          </p>
        </div>

        {/* Часы работы */}
        <div className="flex-1 bg-slate-800 p-8 rounded-lg shadow-lg mt-8 lg:mt-0">
          <h2 className="text-3xl font-semibold text-sky-400 mb-4">
            Business Hours
          </h2>
          <p className="text-lg text-slate-300">
            <strong>Monday - Friday:</strong> 9:00 AM - 6:00 PM
            <br />
            <strong>Saturday:</strong> 10:00 AM - 4:00 PM
            <br />
            <strong>Sunday:</strong> Closed
          </p>
        </div>
      </div>

      {/* Форма обратной связи */}
      <div className="bg-gradient-to-br from-blue-900 to-purple-900 p-10 rounded-lg shadow-2xl">
        <h2 className="text-4xl font-extrabold text-center text-sky-400 mb-8">
          Send Us a Message
        </h2>
        <form className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Полное имя */}
            <div>
              <label
                htmlFor="name"
                className="block text-lg font-medium text-slate-200"
              >
                Full Name
              </label>
              <input
                type="text"
                id="name"
                className="mt-1 p-3 w-full border border-slate-600 rounded-lg shadow-sm focus:outline-none focus:ring-sky-400 focus:border-sky-400 bg-slate-700 text-slate-200"
                placeholder="Enter your full name"
              />
            </div>
            {/* Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-lg font-medium text-slate-200"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 p-3 w-full border border-slate-600 rounded-lg shadow-sm focus:outline-none focus:ring-sky-400 focus:border-sky-400 bg-slate-700 text-slate-200"
                placeholder="Enter your email address"
              />
            </div>
            {/* Название компании для партнеров */}
            <div>
              <label
                htmlFor="company"
                className="block text-lg font-medium text-slate-200"
              >
                Company Name
              </label>
              <input
                type="text"
                id="company"
                className="mt-1 p-3 w-full border border-slate-600 rounded-lg shadow-sm focus:outline-none focus:ring-sky-400 focus:border-sky-400 bg-slate-700 text-slate-200"
                placeholder="Enter your company name"
              />
            </div>
            {/* Телефон */}
            <div>
              <label
                htmlFor="phone"
                className="block text-lg font-medium text-slate-200"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                className="mt-1 p-3 w-full border border-slate-600 rounded-lg shadow-sm focus:outline-none focus:ring-sky-400 focus:border-sky-400 bg-slate-700 text-slate-200"
                placeholder="Enter your phone number"
              />
            </div>
          </div>

          {/* Сообщение */}
          <div>
            <label
              htmlFor="message"
              className="block text-lg font-medium text-slate-200"
            >
              Your Message
            </label>
            <textarea
              id="message"
              rows="5"
              className="mt-1 p-3 w-full border border-slate-600 rounded-lg shadow-sm focus:outline-none focus:ring-sky-400 focus:border-sky-400 bg-slate-700 text-slate-200"
              placeholder="Enter your message"
            ></textarea>
          </div>
          {/* Кнопка отправки */}
          <div className="text-center">
            <button
              type="submit"
              className="bg-sky-400 text-slate-100 px-8 py-3 rounded-lg text-lg font-semibold hover:bg-sky-500 transition duration-300 shadow-lg"
            >
              Submit Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
