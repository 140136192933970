import React from "react";

/**
 * Pricing component
 * @returns {jsx}
 */
const Pricing = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      {/* Заголовок страницы */}
      <h1 className="text-5xl font-extrabold text-center text-brand mb-16">
        Find the Right Plan for Your Business
      </h1>

      {/* Блоки тарифов */}
      <div className="flex flex-col lg:flex-row lg:space-x-8 mb-12">
        {/* Тариф Basic */}
        <div className="flex-1 bg-gray-100 p-8 rounded-lg shadow-lg mb-8 lg:mb-0">
          <h2 className="text-3xl font-semibold text-center text-brand mb-4">
            Basic
          </h2>
          <p className="text-lg text-gray-700 text-center mb-6">
            For startups and small teams
          </p>
          <p className="text-4xl font-bold text-center text-brand mb-8">Free</p>
          <ul className="text-lg text-gray-700 mb-8 space-y-4">
            <li>Global travel inventory & exclusive rates</li>
            <li>Unlimited policy & approval workflows</li>
            <li>Self-serve changes & 24/7 support</li>
            <li>navanilRewards program</li>
          </ul>
          <div className="text-center">
            <button className="bg-brand text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-brand-dark transition duration-300">
              Get Started
            </button>
          </div>
        </div>

        {/* Тариф Professional */}
        <div className="flex-1 bg-gray-100 p-8 rounded-lg shadow-lg mb-8 lg:mb-0 border-t-4 border-brand">
          <h2 className="text-3xl font-semibold text-center text-brand mb-4">
            Professional
          </h2>
          <p className="text-lg text-gray-700 text-center mb-6">
            For growing businesses up to 200 employees
          </p>
          <p className="text-4xl font-bold text-center text-brand mb-8">
            $49 / month
          </p>
          <ul className="text-lg text-gray-700 mb-8 space-y-4">
            <li>All Basic features</li>
            <li>Expense tracking and reimbursements</li>
            <li>HRIS & ERP integrations</li>
            <li>Customizable reports</li>
          </ul>
          <div className="text-center">
            <button className="bg-brand text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-brand-dark transition duration-300">
              Request a Demo
            </button>
          </div>
        </div>

        {/* Тариф Enterprise */}
        <div className="flex-1 bg-gray-100 p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold text-center text-brand mb-4">
            Enterprise
          </h2>
          <p className="text-lg text-gray-700 text-center mb-6">
            For large organizations with custom needs
          </p>
          <p className="text-4xl font-bold text-center text-brand mb-8">
            Contact Us
          </p>
          <ul className="text-lg text-gray-700 mb-8 space-y-4">
            <li>All Professional features</li>
            <li>Unlimited users & global support</li>
            <li>Designated Account Executive</li>
            <li>Custom implementation & onboarding</li>
          </ul>
          <div className="text-center">
            <button className="bg-brand text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-brand-dark transition duration-300">
              Get a Quote
            </button>
          </div>
        </div>
      </div>

      {/* Часто задаваемые вопросы */}
      <div className="mt-12 bg-gray-50 p-8 rounded-lg shadow-lg">
        <h2 className="text-4xl font-bold text-center text-brand mb-8">
          Frequently Asked Questions
        </h2>
        <ul className="space-y-6">
          <li>
            <h3 className="text-xl font-semibold text-gray-800">
              What's the difference between Basic and Professional?
            </h3>
            <p className="text-lg text-gray-700">
              Basic is free and includes essential features for travel.
              Professional adds expense management, custom reports, and HRIS
              integrations.
            </p>
          </li>
          <li>
            <h3 className="text-xl font-semibold text-gray-800">
              How do I switch plans?
            </h3>
            <p className="text-lg text-gray-700">
              You can upgrade or downgrade your plan anytime from your account
              settings. For Enterprise, contact us directly.
            </p>
          </li>
          <li>
            <h3 className="text-xl font-semibold text-gray-800">
              Is there a free trial for the Professional plan?
            </h3>
            <p className="text-lg text-gray-700">
              Yes, we offer a 14-day free trial for the Professional plan. No
              credit card required to start.
            </p>
          </li>
          <li>
            <h3 className="text-xl font-semibold text-gray-800">
              What if I have more than 200 employees?
            </h3>
            <p className="text-lg text-gray-700">
              For larger teams, the Enterprise plan offers customized options to
              suit your business needs.
            </p>
          </li>
        </ul>
      </div>

      {/* Кнопка начать демо */}
      <div className="text-center mt-12">
        <button className="bg-brand text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-brand-dark transition duration-300">
          See Where navanilWill Take You
        </button>
      </div>
    </div>
  );
};

export default Pricing;
