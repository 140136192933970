import HeroCover from "./components/hero-cover/HeroCover";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import banner from "assests/banner1.png";
import logo1 from "assests/logo1.svg";
import logo2 from "assests/logo2.svg";
import logo3 from "assests/logo3.svg";
import logo4 from "assests/logo4.svg";
import logo5 from "assests/logo5.svg";
import logo6 from "assests/logo6.svg";
import logo7 from "assests/logo7.svg";
import logo8 from "assests/logo8.svg";
import logo9 from "assests/logo9.svg";
import logo10 from "assests/logo10.svg";
import logo11 from "assests/logo11.svg";
import booking from "assests/booking.webp";
import support from "assests/support.webp";
import global from "assests/global.webp";
// импорты других логотипов и иконок...

const Home = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate("/contact");
  };

  const faqData = [
    {
      question: "What is navanil?",
      answer:
        "navanil is a B2B platform designed to streamline travel business management.",
    },
    {
      question: "How do I create an account?",
      answer:
        "To create an account, click on 'Login/Register' and follow the sign-up steps.",
    },
    {
      question: "What are the benefits of joining?",
      answer:
        "Our platform offers tools for managing bookings, client relationships, and analytics in one place.",
    },
    {
      question: "How does customer support work?",
      answer:
        "We offer 24/7 customer support through live chat, phone, and email for all account tiers.",
    },
    {
      question: "Can I use navanil on mobile?",
      answer:
        "Yes, our platform is mobile-friendly and accessible on both iOS and Android devices.",
    },
    {
      question: "Is there a free trial available?",
      answer:
        "Yes, we offer a 14-day free trial for new users to explore the platform's features.",
    },
    {
      question: "What payment methods are supported?",
      answer:
        "We accept major credit cards, PayPal, and wire transfers for subscription payments.",
    },
    {
      question: "Can I manage multiple travel agencies with one account?",
      answer:
        "Yes, you can manage multiple agencies under a single account, ideal for franchise businesses.",
    },
    {
      question: "How do I reset my password?",
      answer:
        "Click on 'Forgot Password' on the login page and follow the instructions sent to your email.",
    },
    {
      question: "Where can I find tutorials for the platform?",
      answer:
        "Visit our 'Resources' page for video guides, articles, and step-by-step tutorials.",
    },
  ];

  return (
    <div className="font-sans bg-gradient-to-br from-blue-800 via-purple-700 to-blue-600 text-white">
      {/* Главный баннер с описанием */}
      <div className="relative">
        <img
          src={banner}
          alt="B2B Travel Management"
          className="w-full h-[60vh] object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col justify-center items-center text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Efficient B2B Travel Management
          </h1>
          <p className="text-lg md:text-2xl max-w-3xl mb-6">
            Simplify corporate travel with our seamless booking, cost
            management, and support tools.
          </p>
          <button
            onClick={handleLearnMoreClick}
            className="bg-blue-500 hover:bg-purple-500 transition-colors text-white px-6 py-3 rounded-lg font-semibold"
          >
            Learn more
          </button>
        </div>
      </div>

      {/* Секция с клиентами */}
      <section className="py-16 bg-blue-700">
        <h2 className="text-3xl font-bold text-center mb-8">Our Clients</h2>
        <div className="flex flex-wrap justify-center items-center gap-8">
          <img
            src={logo1}
            alt="Client 1"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo2}
            alt="Client 2"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo3}
            alt="Client 3"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo4}
            alt="Client 4"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo5}
            alt="Client 5"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo6}
            alt="Client 6"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo7}
            alt="Client 7"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo8}
            alt="Client 8"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo9}
            alt="Client 9"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo10}
            alt="Client 10"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo11}
            alt="Client 11"
            className="w-32 h-32 object-contain"
          />
          {/* Добавить остальные логотипы... */}
        </div>
      </section>

      {/* Секция "шахматкой" с информационными блоками */}
      <section className="py-16">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="flex flex-col justify-center order-1 md:order-1">
            <h3 className="text-2xl font-bold mb-4">Effortless Booking</h3>
            <p className="text-gray-300">
              Manage bookings efficiently with real-time tracking and flexible
              options.
            </p>
          </div>
          <img
            src={booking}
            alt="Booking Image"
            className="rounded-lg shadow-md order-2 md:order-2"
          />

          <img
            src={support}
            alt="Support Image"
            className="rounded-lg shadow-md order-4 md:order-3"
          />
          <div className="flex flex-col justify-center order-3 md:order-4">
            <h3 className="text-2xl font-bold mb-4">24/7 Support</h3>
            <p className="text-gray-300">
              Get help anytime with our dedicated support team, available 24/7.
            </p>
          </div>
          {/* Другие блоки "шахматкой" с текстом и картинками... */}
        </div>
      </section>

      {/* Информационный блок с одной картинкой */}
      <section className="py-16 bg-gradient-to-r from-blue-600 to-purple-600">
        <div className="max-w-5xl mx-auto text-center">
          <img src={global} alt="Global Image" className="h-128 mx-auto mb-6" />
          <h2 className="text-3xl font-bold mb-4">Global Network</h2>
          <p className="text-lg text-gray-200">
            Leverage our global network of trusted partners for seamless travel
            experiences.
          </p>
        </div>
      </section>

      {/* Блок с вопросами и ответами (FAQ) */}
      <section className="py-16">
        <h2 className="text-3xl font-bold text-center mb-8">FAQs</h2>
        <div className="max-w-5xl mx-auto">
          <div className="space-y-4">
            {faqData.map((faq, index) => (
              <details
                key={index}
                className="bg-white bg-opacity-10 p-4 rounded-md text-gray-200"
              >
                <summary className="font-semibold cursor-pointer">
                  {faq.question}
                </summary>
                <p className="mt-2">{faq.answer}</p>
              </details>
            ))}
          </div>
        </div>
      </section>

      {/* Блок с отзывами */}
      <section className="py-16 bg-gradient-to-br from-purple-700 via-blue-800 to-blue-600">
        <h2 className="text-3xl font-bold text-center mb-8">
          Client Testimonials
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white bg-opacity-20 p-6 rounded-lg shadow-lg text-center">
            <p className="text-xl mb-4 text-gray-200">
              "Navanil transformed our corporate travel management! Highly
              recommend!"
            </p>
            <p className="text-lg font-semibold text-blue-200">
              - Alex P., Company XYZ
            </p>
          </div>
          <div className="bg-white bg-opacity-20 p-6 rounded-lg shadow-lg text-center">
            <p className="text-xl mb-4 text-gray-200">
              "Efficient and user-friendly platform. Our team loved the support
              and flexibility."
            </p>
            <p className="text-lg font-semibold text-blue-200">
              - Maya T., Corp Alpha
            </p>
          </div>
          {/* Другие отзывы... */}
        </div>
      </section>
    </div>
  );
};

export default Home;
